<template>
  <div id="nasional-distribusi-add-peternak-farm" data-app>
    <!--begin::Form-->
    <ValidationObserver ref="formAdd">
    <b-form @submit="onSubmit" @reset="onReset" v-if="show">
      
      <!--begin::Card-->
      <div class="card card-custom">

        <!--begin::Header-->
        <div class="card-header py-3">
            <div class="card-title align-items-start flex-column">
				        <h3 class="card-label font-weight-bolder text-dark">Informasi Farm Peternak</h3>
                <span class="text-muted font-weight-bold font-size-sm mt-1">Tambah data informasi farm/kandang peternak disini</span>
            </div>
        </div>
        <!--end::Header-->

        <div class="card-body">
            <!--begin::Form Group-->
            <div class="form-group row">
                <label class="col-xl-3 col-lg-3 col-form-label">Nama Farm</label>
                <div class="col-lg-9 col-xl-7">
                    <validationProvider name="Nama Farm" rules="required|min:5|max:50" v-slot="{ valid, errors }">
                        <b-form-group
                            id="input-group-farm-1" 
                            label-for="input-farm-1"
                            :invalid-feedback="errors[0]"
                            class="mb-0"
                            :state="valid">
                            <b-form-input
                            id="input-farm-1"
                            v-model="form.nama"
                            class="form-control form-control-solid"
                            autocomplete="off"
                            required
                            size="lg"
                            placeholder="Nama Farm"
                            :state="valid"
                            ></b-form-input>
                        </b-form-group>
                    </validationProvider>
                </div>
            </div>
            <!--begin::Form Group-->
            <div class="form-group row">
                <label class="col-xl-3 col-lg-3 col-form-label">Sistem Kandang</label>
                <div class="col-lg-9 col-xl-7">
                    <validationProvider name="Sistem Kandang" v-slot="{ valid, errors }">
                        <b-form-group
                            id="input-group-farm-3" 
                            label-for="input-farm-3"
                            :invalid-feedback="errors[0]"
                            class="mb-0"
                            :state="valid">
                            <b-form-select2
                            id="input-farm-3"
                            v-model="form.sistem_kandang"
                            variant="solid"
                            size="lg"
                            :options="opsSistemKandang"
                            placeholder="Silahkan pilih Sistem Kandang"
                            :state="valid"
                            ></b-form-select2>
                        </b-form-group>
                    </validationProvider>
                </div>
            </div>
            
            <!--begin::Form Group-->
            <div class="separator separator-dashed my-5 mt-15"></div>
            <!--begin::Form Group-->
            <div class="row">
                <label class="col-xl-3"></label>
                <div class="col-lg-9 col-xl-7">
                    <h5 class="font-weight-bold mb-6">Legalitas Farm:</h5>
                </div>
            </div>
            <!--begin::Form Group-->
            <div class="form-group row">
                <label class="col-xl-3 col-lg-3 col-form-label">Kepemilikan</label>
                <div class="col-lg-9 col-xl-7">
                    <validationProvider name="Kepemilikan" v-slot="{ valid, errors }">
                        <b-form-group
                            id="input-group-farm-3" 
                            label-for="input-farm-3"
                            :invalid-feedback="errors[0]"
                            class="mb-0"
                            :state="valid">
                            <b-form-select2
                            id="input-farm-3"
                            v-model="form.milik"
                            variant="solid"
                            size="lg"
                            :options="opsKepemilikan"
                            placeholder="Silahkan pilih Kepemilikan"
                            :state="valid"
                            ></b-form-select2>
                        </b-form-group>
                        <span class="form-text text-muted">Isi sesuai dengan legalitas kepemilikan farm.</span>
                    </validationProvider>
                </div>
            </div>
            <!--begin::Form Group-->
            <div v-if="kepemilikan != 1">
              <div class="form-group row">
                  <label class="col-xl-3 col-lg-3 col-form-label">Pemilik Farm</label>
                  <div class="col-lg-9 col-xl-7">
                      <validationProvider name="Pemilik Farm" rules="required|min:5|max:255" v-slot="{ valid, errors }">
                          <b-form-group
                              id="input-group-farm-4" 
                              label-for="input-farm-4"
                              :invalid-feedback="errors[0]"
                              class="mb-0"
                              :state="valid">
                              <b-form-input
                              id="input-farm-4"
                              v-model="form.hakmilik"
                              class="form-control form-control-solid"
                              autocomplete="off"
                              required
                              size="lg"
                              placeholder="Nama Pemilik Farm"
                              :state="valid"
                              ></b-form-input>
                          </b-form-group>
                          <span class="form-text text-muted">Isi dengan nama lengkap Perusahaan/Instansi/Perorangan.</span>
                      </validationProvider>
                  </div>
              </div>
            </div>

            <!--begin::Form Group-->
            <div class="separator separator-dashed my-5 mt-15"></div>
            <!--begin::Form Group-->
            <div class="row">
                <label class="col-xl-3"></label>
                <div class="col-lg-9 col-xl-7">
                    <h5 class="font-weight-bold mb-6">Lokasi:</h5>
                </div>
            </div>              
            <!--begin::Form Group-->
            <div class="form-group row">
                <label class="col-xl-3 col-lg-3 col-form-label">Provinsi</label>
                <div class="col-lg-9 col-xl-7">
                    <validationProvider name="Provinsi" rules="required" v-slot="{ valid, errors }">
                        <b-form-group
                            id="input-group-farm-8" 
                            label-for="input-farm-8"
                            :invalid-feedback="errors[0]"
                            class="mb-0"
                            :state="valid">
                            <b-form-select2
                            id="input-farm-8"
                            v-model="form.provinsi"
                            required
                            variant="solid"
                            size="lg"
                            :options="opsProvinsi"
                            placeholder="Silahkan pilih Provinsi"
                            :state="valid"
                            ></b-form-select2>
                        </b-form-group>
                    </validationProvider>
                </div>
            </div>                        
            <!--begin::Form Group-->
            <div class="form-group row">
                <label class="col-xl-3 col-lg-3 col-form-label">Kabupaten</label>
                <div class="col-lg-9 col-xl-7">
                    <validationProvider name="Kabupaten" rules="required" v-slot="{ valid, errors }">
                        <b-form-group
                            id="input-group-farm-9" 
                            label-for="input-farm-9"
                            :invalid-feedback="errors[0]"
                            class="mb-0"
                            :state="valid">
                            <b-form-select2
                            id="input-farm-9"
                            v-model="form.kabupaten"
                            required
                            variant="solid"
                            size="lg"
                            :options="opsKabupaten"
                            placeholder="Silahkan pilih Kabupaten"
                            :state="valid"
                            ></b-form-select2>
                        </b-form-group>
                    </validationProvider>
                </div>
            </div>          
            <!--begin::Form Group-->
            <div class="form-group row">
                <label class="col-xl-3 col-lg-3 col-form-label">Kecamatan</label>
                <div class="col-lg-9 col-xl-7">
                    <validationProvider name="Kecamatan" rules="required" v-slot="{ valid, errors }">
                        <b-form-group
                            id="input-group-farm-10" 
                            label-for="input-farm-10"
                            :invalid-feedback="errors[0]"
                            class="mb-0"
                            :state="valid">
                            <b-form-select2
                            id="input-farm-10"
                            v-model="form.kecamatan"
                            required
                            variant="solid"
                            size="lg"
                            :options="opsKecamatan"
                            placeholder="Silahkan pilih Kecamatan"
                            :state="valid"
                            ></b-form-select2>
                        </b-form-group>
                    </validationProvider>
                </div>
            </div>
            <!--begin::Form Group-->
            <div class="form-group row">
                <label class="col-xl-3 col-lg-3 col-form-label">Desa</label>
                <div class="col-lg-9 col-xl-7">
                    <validationProvider name="Desa" rules="required" v-slot="{ valid, errors }">
                        <b-form-group
                            id="input-group-farm-10" 
                            label-for="input-farm-10"
                            :invalid-feedback="errors[0]"
                            class="mb-0"
                            :state="valid">
                            <b-form-select2
                            id="input-farm-10"
                            v-model="form.desa"
                            required
                            variant="solid"
                            size="lg"
                            :options="opsDesa"
                            placeholder="Silahkan pilih Desa"
                            :state="valid"
                            ></b-form-select2>
                        </b-form-group>
                    </validationProvider>
                </div>
            </div>
            <!--begin::Form Group-->
            <div class="form-group row">
                <label class="col-xl-3 col-lg-3 col-form-label">Alamat</label>
                <div class="col-lg-9 col-xl-7">
                    <validationProvider name="Alamat" rules="required|max:255" v-slot="{ valid, errors }">
                        <b-form-group
                            id="input-group-farm-11" 
                            label-for="input-farm-11"
                            :invalid-feedback="errors[0]"
                            class="mb-0"
                            :state="valid">
                            <b-form-input
                            id="input-farm-11"
                            v-model="form.alamat"
                            class="form-control form-control-solid"
                            autocomplete="off"
                            required
                            size="lg"
                            placeholder="Alamat"
                            :state="valid"
                            ></b-form-input>
                        </b-form-group>
                        <span class="form-text text-muted">Tulis nama jalan, nomor rumah, nomor kompleks, nama gedung, lantai atau nomor unit.</span>
                    </validationProvider>
                </div>
            </div>
            <!--begin::Form Group-->
            <div class="form-group row">
                <label class="col-xl-3 col-lg-3 col-form-label">Tag Lokasi</label>
                <div class="col-lg-9 col-xl-7">
                  <b-form-group
                      id="input-group-farm-15" 
                      label-for="gmaps"
                      invalid-feedback="Tag lokasi wajib diisi"
                      class="mb-0"
                      :state="cekgmaps">
                      <div style="display: none">
                        <input
                          id="pac-input"
                          ref="alamat"
                          class="controls"
                          type="text"
                          placeholder="Enter a location"
                        />
                      </div>

                      <div class="rounded iframe-content">
                        <div id="gmaps" ref="goomaps"></div>
                      </div>
                  </b-form-group>
                </div>
            </div>

            <!--begin::Form Group-->
            <div class="separator separator-dashed my-5 mt-15"></div>
            <!--begin::Form Group-->
            <div class="row">
                <label class="col-xl-3"></label>
                <div class="col-lg-9 col-xl-7">
                    <h5 class="font-weight-bold mb-6">Rincian:</h5>
                </div>
            </div>       
            <!--begin::Form Group-->
            <div class="form-group row">
                <label class="col-xl-3 col-lg-3 col-form-label">Jml Kandang</label>
                <div class="col-lg-9 col-xl-7">
                    <validationProvider name="Jml Kandang" rules="min_value:0|max_value:1000000000" v-slot="{ valid, errors }">
                        <b-form-group
                            id="input-group-farm-12" 
                            label-for="input-farm-12"
                            :invalid-feedback="errors[0]"
                            class="mb-0"
                            :state="valid">
                            <vue-number-input 
                              :attrs="{ id: `input-12`, class: `b-form-spinbutton form-control form-control-solid d-flex align-items-stretch` }"
                              v-model="form.kandang"
                              :min="0"
                              size="large"
                              :max="1000000000"
                              center
                              controls
                            ></vue-number-input>
                        </b-form-group>
                    </validationProvider>
                </div>
            </div> 
            <!--begin::Form Group-->
            <div class="form-group row">
                <label class="col-xl-3 col-lg-3 col-form-label">Jml Flock</label>
                <div class="col-lg-9 col-xl-7">
                    <validationProvider name="Jml Flock" rules="min_value:0|max_value:1000000000" v-slot="{ valid, errors }">
                        <b-form-group
                            id="input-group-farm-13" 
                            label-for="input-farm-13"
                            :invalid-feedback="errors[0]"
                            class="mb-0"
                            :state="valid">
                            <vue-number-input 
                              :attrs="{ id: `input-13`, class: `b-form-spinbutton form-control form-control-solid d-flex align-items-stretch` }"
                              v-model="form.flock"
                              :min="0"
                              :max="1000000000"
                              size="large"
                              center
                              controls
                            ></vue-number-input>
                        </b-form-group>
                    </validationProvider>
                </div>
            </div>
            <!--begin::Form Group-->
            <div class="form-group row">
                <label class="col-xl-3 col-lg-3 col-form-label">Kapasitas Farm</label>
                <div class="col-lg-9 col-xl-7">
                    <validationProvider name="Kapasitas Farm" rules="min_value:0|max_value:1000000000" v-slot="{ valid, errors }">
                        <b-form-group
                            id="input-group-farm-14" 
                            label-for="input-farm-14"
                            :invalid-feedback="errors[0]"
                            class="mb-0"
                            :state="valid">
                            <vue-number-input 
                              :attrs="{ id: `input-14`, class: `b-form-spinbutton form-control form-control-solid d-flex align-items-stretch` }"
                              v-model="form.kapasitas"
                              :min="0"
                              :max="1000000000"
                              size="large"
                              center
                              controls
                            ></vue-number-input>
                        </b-form-group>
                    </validationProvider>
                </div>
            </div>
            <!--begin::Form Group-->
            <div class="form-group row">
                <label class="col-xl-3 col-lg-3 col-form-label">Jml SDM</label>
                <div class="col-lg-9 col-xl-7">
                    <validationProvider name="Jml SDM" rules="min_value:0|max_value:1000000000" v-slot="{ valid, errors }">
                        <b-form-group
                            id="input-group-farm-15" 
                            label-for="input-farm-15"
                            :invalid-feedback="errors[0]"
                            class="mb-0"
                            :state="valid">
                            <vue-number-input 
                              :attrs="{ id: `input-15`, class: `b-form-spinbutton form-control form-control-solid d-flex align-items-stretch` }"
                              v-model="form.sdm"
                              :min="0"
                              :max="1000000000"
                              size="large"
                              center
                              controls
                            ></vue-number-input>
                        </b-form-group>
                    </validationProvider>
                </div>
            </div>
        </div>

        <div class="card-footer">
          <div class="row">
            <div class="col-lg-3"></div>
            <div class="col-lg-9">
              <button 
                @click="onSubmit" 
                ref="kt_submit"
                class="btn btn-primary font-weight-bold"
              >
                <i class="la la-save"></i>
                Simpan
              </button>
              &nbsp;
              <button
                @click="onReset"
                class="btn btn-clean font-weight-bold"
              >
                <i class="la la-recycle"></i>
                Reset
              </button>
              &nbsp;
              <button
                type="button"
                @click="closeDialog()"
                class="btn btn-danger font-weight-bold"
              >
                <i class="la la-close"></i>
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    <!--end::Form-->
    </b-form>
    </ValidationObserver>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { Loader } from '@googlemaps/js-api-loader';
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import MasterService from "@/core/services/api/master/master.service";
import WilayahService from "@/core/services/api/master/wilayah.service";
import PeternakService from "@/core/services/api/master/peternak.service";
import ErrorService from "@/core/services/error.service";

export default {
  name: "nasional-distribusi-add-peternak-farm",
  props: {
    sdata: {
      required: true
    }
  },
  data() {
    return {
      tabIndex: 0,
      load: false,
      form: {
        peternak: this.sdata.peternak,
        nama: null,
        milik: null,
        hakmilik: null,
        sistem_kandang: null,
        provinsi: null,
        kabupaten: null,
        kecamatan: null,
        desa: null,
        alamat: null,
        geotag: null,
        kandang: 0,
        flock: 0,
        kapasitas: 0,
        sdm: 0
      },
      gooMaps: null,
      gooWindow: null,
      gooMarker: null,
      opsKepemilikan: [
        { text: 'Silahkan pilih Kepemilikan', value: null },
      ],
      opsSistemKandang: [
        { text: 'Silahkan pilih Sistem Kandang', value: null },
      ],
      opsProvinsi: [
        { text: 'Silahkan pilih Provinsi', value: null },
      ],
      opsKabupaten: [
        { text: 'Silahkan pilih Kabupaten', value: null },
      ],
      opsKecamatan: [
        { text: 'Silahkan pilih Kecamatan', value: null },
      ],
      opsDesa: [
        { text: 'Silahkan pilih Desa', value: null },
      ],
      show: true
    }
  },
  computed: {
    ...mapGetters(["currentUser"]),
    kepemilikan() {
      if (!this.form.milik) return 1
      const cekData = this.opsKepemilikan.find(o => o.value === this.form.milik)
      return cekData.jns
    },
    cekgmaps() {
      if (this.form.geotag) return true
      return false
    },
  },
  watch: {
    'form.milik' (val, old) {
      if (val != old) {
        this.form.hakmilik = null
      }
    },
    'form.provinsi' (val, old) {
      if (val != old) {
        this.form.geotag = null
        if (val == null) {
          this.opsKabupaten= [{
            value: null,
            text: "Silahkan pilih Kabupaten"
          }]
          return
        }

        if (this.form.kabupaten && val != this.form.kabupaten.toString().substring(0, 2)) {
          this.form.kabupaten = null
          this.form.kecamatan = null
          this.form.desa = null
        }
        this.getKabupaten({ provinsi: val, aks: 1 }, 'farm')
        // this.centerMaps(val, 'provinsi', 'farm)
        this.deleteMarker('farm')
      }
    },
    'form.kabupaten' (val, old) {
      if (val != old) {
        this.form.geotag = null
        if (val == null) {
          this.opsKecamatan= [{
            value: null,
            text: "Silahkan pilih Kecamatan"
          }]
          return
        }
        if (this.form.kecamatan && val != this.form.kecamatan.toString().substring(0, 4)) {
          this.form.kecamatan = null
          this.form.desa = null
        }
        this.getKecamatan({ kabupaten: val, aks: 1 }, 'farm')
        this.centerMaps(val, 'kabupaten', 'farm')
        this.deleteMarker('farm')
      }
    },
    'form.kecamatan' (val, old) {
      if (val != old) {
        this.form.geotag = null
        if (val == null) {
          this.opsDesa= [{
            value: null,
            text: "Silahkan pilih Desa"
          }]  
          return
        }
        if (this.form.desa && val != this.form.desa.toString().substring(0, 6)) {
          this.form.desa = null
        }
        this.getDesa({ kecamatan: val, aks: 1 }, 'farm')
        // this.centerMaps(val, 'kecamatan', 'farm')
        this.deleteMarker('farm')
      }
    },
    'form.desa' (val, old) {
      if (val != old) {
        this.form.geotag = null
        // this.centerMaps(val, 'desa', 'farm')
        this.deleteMarker('farm')
      }
    }
  },
  methods: {
    closeDialog(data = null) {
        let response = {
          status: true
        }

        if (data) {
          response.refresh = true
          response.data = data
        }
        return this.$emit('close-modal', response)
    },
    centerMaps (kode, ting) {
      if(!this.load) return

      let latlng = {
        lat: -6.200000,
        lng: 106.816666
      }

      if (ting == 'provinsi') {
        const getDT = this.opsProvinsi.find(o => o.value === kode)
        latlng = {
          lat: parseFloat(getDT.lat),
          lng: parseFloat(getDT.lng)
        }
        this.gooMaps.setZoom(12);
      }
      else if (ting == 'kabupaten') {
        const getDT = this.opsKabupaten.find(o => o.value === kode)
        latlng = {
          lat: parseFloat(getDT.lat),
          lng: parseFloat(getDT.lng)
        }
        this.gooMaps.setZoom(13);
      }
      else if (ting == 'kecamatan') {
        const getDT = this.opsKecamatan.find(o => o.value === kode)
        latlng = {
          lat: parseFloat(getDT.lat),
          lng: parseFloat(getDT.lng)
        }
        this.gooMaps.setZoom(13);
      }
      else if (ting == 'desa') {
        const getDT = this.opsDesa.find(o => o.value === kode)
        latlng = {
          lat: parseFloat(getDT.lat),
          lng: parseFloat(getDT.lng)
        }
        this.gooMaps.setZoom(13);
      }
      else {
        latlng = { lat: -6.200000, lng: 106.816666 }
        this.gooMaps.setZoom(11);
      }

      this.gooMaps.setCenter(latlng);
    },
    deleteMarker() {
      if(!this.load) return
      this.gooMarker.setMap(null);
    },
    getKepemilikan() {
      MasterService.getKepemilikan()
        .then((res) => {
            if (!res.data.status) {
                ErrorService.message(res.data)
                this.opsKepemilikan = [{
                  value: null,
                  text: "Silahkan pilih Kepemilikan"
                }]
                return;
            }

            this.opsKepemilikan = res.data.data
            this.opsKepemilikan.push({
              value: null,
              text: "Silahkan pilih Kepemilikan"
            })
        })
        .catch((err) => {
          this.opsKepemilikan = [{
            value: null,
            text: "Tidak ada Kepemilikan"
          }]
          ErrorService.status(err)
        })
    },
    getSistemKandang() {
      MasterService.getSistemKandang()
        .then((res) => {
            if (!res.data.status) {
                ErrorService.message(res.data)
                this.opsSistemKandang = [{
                  value: null,
                  text: "Silahkan pilih Sistem Kandang"
                }]
                return;
            }

            this.opsSistemKandang = res.data.data
            this.opsSistemKandang.push({
              value: null,
              text: "Silahkan pilih Sistem Kandang"
            })
        })
        .catch((err) => {
          this.opsSistemKandang = [{
            value: null,
            text: "Tidak ada Sistem Kandang"
          }]
          ErrorService.status(err)
        })
    },
    getProvinsi() {
      WilayahService.getProvinsi({ aks: 1 })
        .then((res) => {
            if (!res.data.status) {
                ErrorService.message(res.data)
                this.opsProvinsi = [{
                  value: null,
                  text: "Silahkan pilih Provinsi"
                }]
                return;
            }

            this.opsProvinsi = res.data.data
            this.opsProvinsi.push({
              value: null,
              text: "Silahkan pilih Provinsi"
            })
        })
        .catch((err) => {
          this.opsProvinsi = [{
            value: null,
            text: "Tidak ada Provinsi"
          }]
          ErrorService.status(err)
        })
    },
    getKabupaten(param) {
      if (!param) {
        this.opsKabupaten = [{
          value: null,
          text: "Silahkan pilih Kabupaten"
        }]
        return;
      }
      
      WilayahService.getKabupaten(param)
        .then((res) => {
            if (!res.data.status) {
                ErrorService.message(res.data)
                this.opsKabupaten = [{
                  value: null,
                  text: "Silahkan pilih Kabupaten"
                }]
                return;
            }

            this.opsKabupaten = res.data.data
            this.opsKabupaten.push({
              value: null,
              text: "Silahkan pilih Kabupaten"
            })
        })
        .catch((err) => {
          this.opsKabupaten = [{
            value: null,
            text: "Tidak ada Kabupaten"
          }]
          ErrorService.status(err)
        })
    },
    getKecamatan(param) {
      if (!param) {
        this.opsKecamatan = [{
          value: null,
          text: "Silahkan pilih Kecamatan"
        }]
        return;
      }

      WilayahService.getKecamatan(param)
        .then((res) => {
            if (!res.data.status) {
                ErrorService.message(res.data)
                this.opsKecamatan = [{
                  value: null,
                  text: "Silahkan pilih Kecamatan"
                }]
                return;
            }

            this.opsKecamatan = res.data.data
            this.opsKecamatan.push({
              value: null,
              text: "Silahkan pilih Kecamatan"
            })
        })
        .catch((err) => {
          this.opsKecamatan = [{
            value: null,
            text: "Tidak ada Kecamatan"
          }]
          ErrorService.status(err)
        })
    },
    getDesa(param) {
      if (!param) {
        this.opsDesa = [{
          value: null,
          text: "Silahkan pilih Desa"
        }]
        return;
      }

      WilayahService.getDesa(param)
        .then((res) => {
            if (!res.data.status) {
                ErrorService.message(res.data)
                this.opsDesa = [{
                  value: null,
                  text: "Silahkan pilih Desa"
                }]
                return;
            }

            this.opsDesa = res.data.data
            this.opsDesa.push({
              value: null,
              text: "Silahkan pilih Desa"
            })
        })
        .catch((err) => {
          this.opsDesa = [{
            value: null,
            text: "Tidak ada Desa"
          }]
          ErrorService.status(err)
        })
    },
    initMaps() {
      const loader = new Loader({
          apiKey: process.env.VUE_APP_GOOGLE_MAP_API_KEY,
          version: "weekly",
          libraries: ["places"]
      });

      loader.load()
        .then((google) => {
          this.gooMaps = new google.maps.Map(this.$refs.goomaps, {
            center: this.form.geotag,
            zoom: 11,
            maId: "f7a6bf3173b56abc",
            controlSize: 25,
            mapTypeId: "roadmap",
            mapTypeControlOptions: {
              mapTypeIds: [],
            },
            streetViewControl: false

          });

          this.gooWindow = new google.maps.InfoWindow({ content: "Lokasi dipilih" });
          this.gooMarker = new google.maps.Marker({ position: this.form.geotag, map: this.gooMaps });

          const input = this.$refs.alamat;
          this.gooMaps.controls[google.maps.ControlPosition.TOP_LEFT].push(input);

          const autocomplete = new google.maps.places.Autocomplete(input);
          autocomplete.bindTo("bounds", this.gooMaps);
          autocomplete.setFields(["place_id", "geometry"]);

          if (!this.load) {
            this.load = true
          }

          this.gooMaps.addListener("click", (mapsMouseEvent) => {
            this.gooWindow.close();
            this.gooMarker.setMap(null);

            this.gooMarker = new google.maps.Marker({ 
              position: mapsMouseEvent.latLng,
              map: this.gooMaps 
            });
            
            this.gooMarker.setVisible(true);
            this.gooWindow.open(this.gooMaps, this.gooMarker);

            this.form.geotag = mapsMouseEvent.latLng.toJSON()
          });

          autocomplete.addListener("place_changed", () => {
            this.gooWindow.close();
            const place = autocomplete.getPlace();

            if (!place.geometry || !place.geometry.location) {
              return;
            }

            if (place.geometry.viewport) {
              this.gooMaps.fitBounds(place.geometry.viewport);
            } else {
              this.gooMaps.setCenter(place.geometry.location);
              this.gooMaps.setZoom(17);
            }
            
            this.gooMarker.setPlace({
              placeId: place.place_id,
              location: place.geometry.location,
            });

            this.gooMarker.setVisible(true);
            this.gooWindow.open(this.gooMaps, this.gooMarker);
            
            this.form.geotag = place.geometry.location.toJSON()
          });

        })
        .catch(err => {
            ErrorService.message({
              action: 'Peringatan',
              message: 'Maps gagal dimuat',
            })
        })
    },
    removeSpinner(button) { 
      button.classList.remove(
        "spinner",
        "spinner-light",
        "spinner-right"
      );
    },
    onSubmit(evt) {
      evt.preventDefault()
      this.$refs.formAdd.validate().then(success => {
        if (!success) {
            tErrorService.message({
              action: 'Peringatan',
              message: 'Silahkan lengkapi form data terlebih dahulu',
            })
            return;
        }

        if(!this.form.milik) {
            this.form.milik = 1
        }

        // set spinner to submit button
        const submitButton = this.$refs["kt_submit"];
        submitButton.classList.add("spinner", "spinner-light", "spinner-right");

        // dummy delay
        // setTimeout(() => {
            PeternakService.addPeternakFarm(this.form)
                .then((res) => {
                    this.removeSpinner(submitButton)
                    ErrorService.message(res.data)
                    
                    if (res.data.status) {
                      this.closeDialog({
                          name: res.data.data.nama
                        })
                    }

                    return;
                })
                .catch((err) => {
                  this.removeSpinner(submitButton)
                  ErrorService.status(err)
                })

        // }, 1000);
      })
    },
    onReset(evt) {
      evt.preventDefault()
      // Reset our form values
      
      this.form.nama = {
        peternak: this.sdata.peternak,
        nama: null,
        milik: null,
        hakmilik: null,
        sistem_kandang: null,
        provinsi: null,
        kabupaten: null,
        kecamatan: null,
        desa: null,
        alamat: null,
        geotag: null,
        kandang: 0,
        flock: 0,
        kapasitas: 0,
        sdm: 0
      }

      // Trick to reset/clear native browser form validation state
      this.show = false
      this.$nextTick(() => {
        this.show = true
        setTimeout(() => {
          this.initMaps()        
        }, 500);
      })
    }
  },
  async mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Master Data Peternak Farm" }]);
    if (!this.currentUser.menu.peternak_farm) {
      return this.$router.push({ name: 'dashboard'}).catch(()=>{})
    }
    this.getSistemKandang()
    this.getProvinsi()
    this.getKepemilikan()
    this.initMaps()        
  },
};
</script>


<style lang="scss">
  #nasional-distribusi-add-peternak-farm {
    .iframe-content {
      height: 400px;
      overflow: auto;
      
      #gmaps {
        height: 100%;
      }
    }

    .controls {
      background-color: #fff;
      border-radius: 2px;
      border: 1px solid transparent;
      box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
      box-sizing: border-box;
      font-family: Roboto;
      font-size: 15px;
      font-weight: 300;
      height: 29px;
      margin-top: 10px;
      outline: none;
      padding: 0 11px 0 13px;
      text-overflow: ellipsis;
    }

    .controls:focus {
      border-color: #4d90fe;
    }

  }
</style>