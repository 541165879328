import { render, staticRenderFns } from "./AddFarm.vue?vue&type=template&id=2aec1816&"
import script from "./AddFarm.vue?vue&type=script&lang=js&"
export * from "./AddFarm.vue?vue&type=script&lang=js&"
import style0 from "./AddFarm.vue?vue&type=style&index=0&id=2aec1816&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports