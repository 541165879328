<template>
  <div id="nasional-distribusi-add-master-peternak" data-app>
    
    <!--begin::Form-->
    <ValidationObserver ref="formAdd">
    <b-form @submit="onSubmit" @reset="onReset" v-if="show">
      
      <!--begin::Card-->
      <div class="card card-custom">

        <!--begin::Header-->
        <div class="card-header py-3">
            <div class="card-title align-items-start flex-column">
				        <h3 class="card-label font-weight-bolder text-dark">Informasi Peternak</h3>
                <span class="text-muted font-weight-bold font-size-sm mt-1">Tambah data informasi peternak disini</span>
            </div>
        </div>
        <!--end::Header-->

        <div class="card-body">
            <!--begin::Form Group-->
            <div class="form-group row">
                <label class="col-xl-3 col-lg-3 col-form-label">Kategori Usaha</label>
                <div class="col-lg-9 col-xl-7">
                    <validationProvider name="Kategori Usaha" rules="required" v-slot="{ valid, errors }">
                        <b-form-group
                            id="input-group-peternak-1" 
                            label-for="input-peternak-1"
                            :invalid-feedback="errors[0]"
                            class="mb-0"
                            :state="valid">
                            <b-form-select2
                              id="input-peternak-1"
                              v-model="form.kategori"
                              :options="opsKategoriUsaha"
                              required
                              variant="solid"
                              size="lg"
                              placeholder="Silahkan pilih Kategori Usaha"
                              :state="valid"
                            ></b-form-select2>
                        </b-form-group>
                    </validationProvider>
                </div>
            </div>
            <!--begin::Form Group-->
            <div v-if="identitas && (identitas == 'NIB' || identitas == 'NIKP')" class="form-group row">
                <label class="col-xl-3 col-lg-3 col-form-label">Nama Poultry Shop</label>
                <div class="col-lg-9 col-xl-7">
                    <validationProvider :name="`Nama Poultry Shop`" rules="required|min:5|max:50" v-slot="{ valid, errors }">
                        <b-form-group
                            id="input-group-peternak-2" 
                            label-for="input-peternak-2"
                            :invalid-feedback="errors[0]"
                            class="mb-0"
                            :state="valid">
                            <b-form-input
                            autocomplete="off"
                              id="input-peternak-2"
                              v-model="form.nama"
                              class="form-control form-control-solid"
                              required
                              size="lg"
                              :placeholder="`Nama Poultry Shop`"
                              :state="valid"
                              ></b-form-input>
                        </b-form-group>
                    </validationProvider>
                </div>
            </div>
            <!--begin::Form Group-->
            <div v-if="identitas && identitas == 'NIB'" class="form-group row">
                <label class="col-xl-3 col-lg-3 col-form-label">NIB</label>
                <div class="col-lg-9 col-xl-7">
                    <validationProvider :name="`NIB`" rules="required|numeric|digits:13" v-slot="{ valid, errors }">
                        <b-form-group
                            id="input-group-peternak-3" 
                            label-for="input-peternak-3"
                            :invalid-feedback="errors[0]"
                            class="mb-0"
                            :state="valid">
                            <b-form-input
                            autocomplete="off"
                              id="input-peternak-3"
                              v-model="form.nib"
                              class="form-control form-control-solid"
                              required
                              size="lg"
                              :placeholder="`NIB Poultry Shop`"
                              :state="valid"
                              ></b-form-input>
                        </b-form-group>
                    </validationProvider>
                </div>
            </div>

            <div v-if="identitas">
              <!--begin::Form Group-->
              <div class="separator separator-dashed my-5 mt-15"></div>
              <!--begin::Form Group-->
              <div class="row">
                  <label class="col-xl-3"></label>
                  <div class="col-lg-9 col-xl-7">
                      <h5 v-if="identitas == 'NIB' || identitas == 'NIKP'" class="font-weight-bold mb-6">Data Penanggung Jawab:</h5>
                      <h5 v-else class="font-weight-bold mb-6">Data Peternak:</h5>
                  </div>
              </div>
              <!--begin::Form Group-->
              <div class="form-group row">
                  <label class="col-xl-3 col-lg-3 col-form-label">Nama</label>
                  <div class="col-lg-9 col-xl-7">
                      <validationProvider name="Nama Lengkap" rules="required|min:5|max:50" v-slot="{ valid, errors }">
                          <b-form-group
                              id="input-group-peternak-6" 
                              label-for="input-peternak-6"
                              :invalid-feedback="errors[0]"
                              :description="identitas == 'NIB' || identitas == 'NIKP' ? 'Isikan penanggung jawab peternak seperti manager atau level yang lebih tinggi.' : ''"
                              class="mb-0"
                              :state="valid">
                              <b-form-input
                              autocomplete="off"
                                id="input-peternak-6"
                                v-model="form.pj"
                                class="form-control form-control-solid"
                                required
                                size="lg"
                                placeholder="Nama Lengkap"
                                :state="valid"
                                ></b-form-input>
                          </b-form-group>
                      </validationProvider>
                  </div>
              </div>
              <!--begin::Form Group-->
              <div class="form-group row">
                  <label class="col-xl-3 col-lg-3 col-form-label">NIK</label>
                  <div class="col-lg-9 col-xl-7">
                      <validationProvider name="NIK" rules="required|numeric|digits:16" v-slot="{ valid, errors }">
                          <b-form-group
                              id="input-group-peternak-7" 
                              label-for="input-peternak-7"
                              :invalid-feedback="errors[0]"
                              class="mb-0"
                              :state="valid">
                              <b-form-input
                              autocomplete="off"
                                id="input-peternak-7"
                                v-model="form.pj_nik"
                                class="form-control form-control-solid"
                                required
                                size="lg"
                                placeholder="Nomor Induk Kependudukan"
                                :state="valid"
                                ></b-form-input>
                          </b-form-group>
                      </validationProvider>
                  </div>
              </div>
              <!--begin::Form Group-->
              <div class="form-group row">
                  <label class="col-xl-3 col-lg-3 col-form-label">Email</label>
                  <div class="col-lg-9 col-xl-7">
                      <validationProvider name="Email" rules="email" v-slot="{ valid, errors }">
                          <b-form-group
                              id="input-group-peternak-8" 
                              label-for="input-peternak-8"
                              :invalid-feedback="errors[0]"
                              class="mb-0"
                              :state="valid">
                              <b-form-input
                              autocomplete="off"
                                id="input-peternak-8"
                                v-model="form.pj_email"
                                class="form-control form-control-solid"
                                size="lg"
                                placeholder="Email"
                                :state="valid"
                                ></b-form-input>
                          </b-form-group>
                      </validationProvider>
                  </div>
              </div>
              <!--begin::Form Group-->
              <div class="form-group row">
                  <label class="col-xl-3 col-lg-3 col-form-label">No. Ponsel</label>
                  <div class="col-lg-9 col-xl-7">
                      <validationProvider name="No. Ponsel" rules="required|numeric|max:20" v-slot="{ valid, errors }">
                          <b-form-group
                              id="input-group-peternak-9" 
                              label-for="input-peternak-9"
                              :invalid-feedback="errors[0]"
                              class="mb-0"
                              :state="valid">
                              <b-input-group prepend="+62" class="border-none">
                                <b-input 
                                  autocomplete="off"
                                  id="input-peternak-9" 
                                  v-model="form.pj_telp"
                                  class="form-control form-control-solid"
                                  required
                                  size="lg"
                                  placeholder="Nomor Ponsel"
                                  :state="valid"
                              ></b-input>
                              </b-input-group>
                          </b-form-group>
                          <span class="form-text text-muted">Angka nol (0) didepan tidak ditulis.</span>
                      </validationProvider>
                  </div>
              </div>
            </div>

            <!--begin::Form Group-->
            <div class="separator separator-dashed my-5 mt-15"></div>
            <!--begin::Form Group-->
            <div class="row">
                <label class="col-xl-3"></label>
                <div class="col-lg-9 col-xl-7">
                    <h5 class="font-weight-bold mb-6">Lokasi:</h5>
                </div>
            </div>              
            <!--begin::Form Group-->
            <div class="form-group row">
                <label class="col-xl-3 col-lg-3 col-form-label">Provinsi</label>
                <div class="col-lg-9 col-xl-7">
                    <validationProvider name="Provinsi" rules="required" v-slot="{ valid, errors }">
                        <b-form-group
                            id="input-group-peternak-10" 
                            label-for="input-peternak-10"
                            :invalid-feedback="errors[0]"
                            class="mb-0"
                            :state="valid">
                            <b-form-select2
                            id="input-peternak-10"
                            v-model="form.provinsi"
                            required
                            variant="solid"
                            size="lg"
                            :options="opsProvinsi"
                            placeholder="Silahkan pilih Provinsi"
                            :state="valid"
                            ></b-form-select2>
                        </b-form-group>
                    </validationProvider>
                </div>
            </div>                        
            <!--begin::Form Group-->
            <div class="form-group row">
                <label class="col-xl-3 col-lg-3 col-form-label">Kabupaten</label>
                <div class="col-lg-9 col-xl-7">
                    <validationProvider name="Kabupaten" rules="required" v-slot="{ valid, errors }">
                        <b-form-group
                            id="input-group-peternak-11" 
                            label-for="input-peternak-11"
                            :invalid-feedback="errors[0]"
                            class="mb-0"
                            :state="valid">
                            <b-form-select2
                            id="input-peternak-11"
                            v-model="form.kabupaten"
                            required
                            variant="solid"
                            size="lg"
                            :options="opsKabupaten"
                            placeholder="Silahkan pilih Kabupaten"
                            :state="valid"
                            ></b-form-select2>
                        </b-form-group>
                    </validationProvider>
                </div>
            </div>          
            <!--begin::Form Group-->
            <div class="form-group row">
                <label class="col-xl-3 col-lg-3 col-form-label">Kecamatan</label>
                <div class="col-lg-9 col-xl-7">
                    <validationProvider name="Kecamatan" rules="required" v-slot="{ valid, errors }">
                        <b-form-group
                            id="input-group-peternak-12" 
                            label-for="input-peternak-12"
                            :invalid-feedback="errors[0]"
                            class="mb-0"
                            :state="valid">
                            <b-form-select2
                            id="input-peternak-12"
                            v-model="form.kecamatan"
                            required
                            variant="solid"
                            size="lg"
                            :options="opsKecamatan"
                            placeholder="Silahkan pilih Kecamatan"
                            :state="valid"
                            ></b-form-select2>
                        </b-form-group>
                    </validationProvider>
                </div>
            </div>
            <!--begin::Form Group-->
            <div class="form-group row">
                <label class="col-xl-3 col-lg-3 col-form-label">Desa</label>
                <div class="col-lg-9 col-xl-7">
                    <validationProvider name="Desa" rules="required" v-slot="{ valid, errors }">
                        <b-form-group
                            id="input-group-peternak-13" 
                            label-for="input-peternak-13"
                            :invalid-feedback="errors[0]"
                            class="mb-0"
                            :state="valid">
                            <b-form-select2
                            id="input-peternak-13"
                            v-model="form.desa"
                            required
                            variant="solid"
                            size="lg"
                            :options="opsDesa"
                            placeholder="Silahkan pilih Desa"
                            :state="valid"
                            ></b-form-select2>
                        </b-form-group>
                    </validationProvider>
                </div>
            </div>
            <!--begin::Form Group-->
            <div class="form-group row">
                <label class="col-xl-3 col-lg-3 col-form-label">Alamat</label>
                <div class="col-lg-9 col-xl-7">
                    <validationProvider name="Alamat" rules="required|max:255" v-slot="{ valid, errors }">
                        <b-form-group
                            id="input-group-peternak-14" 
                            label-for="input-peternak-14"
                            :invalid-feedback="errors[0]"
                            class="mb-0"
                            :state="valid">
                            <b-form-input
                            autocomplete="off"
                            id="input-peternak-14"
                            v-model="form.alamat"
                            class="form-control form-control-solid"
                            required
                            size="lg"
                            placeholder="Alamat"
                            :state="valid"
                            ></b-form-input>
                        </b-form-group>
                        <span class="form-text text-muted">Tulis nama jalan, nomor rumah, nomor kompleks, nama gedung, lantai atau nomor unit.</span>
                    </validationProvider>
                </div>
            </div>
            <!--begin::Form Group-->
            <div class="form-group row">
                <label class="col-xl-3 col-lg-3 col-form-label">Tag Lokasi</label>
                <div class="col-lg-9 col-xl-7">
                  <b-form-group
                      id="input-group-peternak-15" 
                      label-for="gmaps"
                      invalid-feedback="Tag lokasi wajib diisi"
                      class="mb-0"
                      :state="cekgmaps">
                      <div style="display: none">
                        <input
                          id="pac-input"
                          ref="alamat"
                          class="controls"
                          type="text"
                          placeholder="Enter a location"
                        />
                      </div>

                      <div class="rounded iframe-content">
                        <div id="gmaps" ref="goomaps"></div>
                      </div>
                  </b-form-group>
                </div>
            </div>

            <!--begin::Form Group-->
            <div class="separator separator-dashed my-5 mt-15"></div>
            <!--begin::Form Group-->
            <div class="row">
                <label class="col-xl-3"></label>
                <div class="col-lg-9 col-xl-7">
                    <h5 class="font-weight-bold mb-3">Komoditas:</h5>
                </div>
            </div>   
            <b-row v-for="(rinc, index) in form.usaha" :key="index">
              <b-col cols="10" class="pb-0 pt-0">
                <h5 class="mt-4 text-muted">Rincian {{index + 1}}</h5>
              </b-col>
              <b-col cols="2" class="text-right">
                <b-button v-show="(form.usaha.length > 1) ? true : false" @click="deleteKomoditas(index)" size="sm" variant="link" class="text-hover-primary">
                  <i class="flaticon2-rubbish-bin-delete-button p-0"></i>
                </b-button>
              </b-col>
              <b-col cols="12" sm="4">
                <validationProvider :name="`Rin ${index+1} Komoditas`" rules="required" v-slot="{ valid, errors }">
                  <b-form-group 
                  :id="`input-group-5-${index}-1`" 
                  label="Komoditas:" 
                  :label-for="`input-5-${index}-1`"
                  :invalid-feedback="errors[0]"
                  :state="valid">    
                    <b-form-select2
                      :id="`input-5-${index}-1`"
                      v-model="rinc.komoditas"
                      variant="solid"
                      size="lg"
                      @change="onChangeKomoditas(index)"
                      :options="opsTernakKomoditas"
                      required
                      placeholder="Silahkan pilih Komoditas"
                      :state="valid"
                    ></b-form-select2>
                  </b-form-group>
                </validationProvider>
              </b-col>
              <b-col cols="12" sm="4">
                <validationProvider :name="`Rin ${index+1} Tipe`" rules="required" v-slot="{ valid, errors }">
                  <b-form-group 
                  :id="`input-group-5-${index}-2`" 
                  label="Tipe:" 
                  :label-for="`input-5-${index}-2`"
                  :invalid-feedback="errors[0]"
                  :state="valid">          
                    <b-form-select2
                      :id="`input-5-${index}-2`"
                      v-model="rinc.tipe"
                      variant="solid"
                      size="lg"
                      :options="getTipeByKomoditas(rinc.komoditas)"
                      required
                      placeholder="Silahkan pilih Tipe"
                      :state="valid"
                    ></b-form-select2>
                  </b-form-group>
                </validationProvider>
              </b-col>
              <b-col cols="12" sm="4">
                <validationProvider :name="`Rin ${index+1} Jenis Usaha`" rules="required" v-slot="{ valid, errors }">
                  <b-form-group 
                  :id="`input-group-5-${index}-3`" 
                  label="Jenis Usaha:" 
                  :label-for="`input-5-${index}-3`"
                  :invalid-feedback="errors[0]"
                  :state="valid">
                    <b-form-select2
                      :id="`input-5-${index}-3`"
                      v-model="rinc.usaha"
                      variant="solid"
                      size="lg"
                      :options="getUsahaByKomoditas(rinc.komoditas)"
                      required
                      placeholder="Silahkan pilih Jenis Usaha"
                      :state="valid"
                    ></b-form-select2>
                  </b-form-group>
                </validationProvider>
              </b-col>
            </b-row>

            <b-row>
              <b-col cols="12" class="text-right">
                <b-button @click="addKomoditas" variant="link" class="text-hover-primary">
                  <i class="flaticon2-plus-1 p-0"></i>
                  Add Komoditas
                </b-button>
              </b-col>
            </b-row>

            <!--begin::Form Group-->
            <div class="separator separator-dashed my-5 mt-15"></div>
            <!--begin::Form Group-->
            <div class="row">
                <label class="col-xl-3"></label>
                <div class="col-lg-9 col-xl-7">
                    <h5 class="font-weight-bold mb-6">Data Farm/Kandang:</h5>
                </div>
            </div>
            <!--begin::Form Group-->
            <div class="form-group row">
                <label class="col-xl-3 col-lg-3 col-form-label">Nama</label>
                <div class="col-lg-9 col-xl-7">
                    <validationProvider name="Nama Farm/Kandang" rules="required|min:5|max:50" v-slot="{ valid, errors }">
                        <b-form-group
                            id="input-group-peternak-20" 
                            label-for="input-peternak-20"
                            :invalid-feedback="errors[0]"
                            class="mb-0"
                            :state="valid">
                            <b-form-input
                            autocomplete="off"
                              id="input-peternak-20"
                              v-model="form.farm.nama"
                              class="form-control form-control-solid"
                              required
                              size="lg"
                              placeholder="Nama Farm/Kandang"
                              :state="valid"
                              ></b-form-input>
                        </b-form-group>
                    </validationProvider>
                </div>
            </div>
            <!--begin::Form Group-->
            <div class="form-group row">
                <label class="col-xl-3 col-lg-3 col-form-label">Sistem Kandang</label>
                <div class="col-lg-9 col-xl-7">
                    <validationProvider name="Sistem Kandang" v-slot="{ valid, errors }">
                        <b-form-group
                            id="input-group-peternak-3" 
                            label-for="input-peternak-3"
                            :invalid-feedback="errors[0]"
                            class="mb-0"
                            :state="valid">
                            <b-form-select2
                              id="input-peternak-3"
                              v-model="form.farm.sistem_kandang"
                              :options="opsSistemKandang"
                              variant="solid"
                              size="lg"
                              placeholder="Silahkan pilih Sistem Kandang"
                              :state="valid"
                            ></b-form-select2>
                        </b-form-group>
                    </validationProvider>
                </div>
            </div>
            <!--begin::Form Group-->
            <div class="form-group row">
                <label class="col-xl-3 col-lg-3 col-form-label">Kepemilikan</label>
                <div class="col-lg-9 col-xl-7">
                    <validationProvider name="Kepemilikan" v-slot="{ valid, errors }">
                        <b-form-group
                            id="input-group-peternak-21" 
                            label-for="input-peternak-21"
                            :invalid-feedback="errors[0]"
                            class="mb-0"
                            :state="valid">
                            <b-form-select2
                            id="input-peternak-21"
                            v-model="form.farm.milik"
                            variant="solid"
                            size="lg"
                            :options="opsKepemilikan"
                            placeholder="Silahkan pilih Kepemilikan"
                            :state="valid"
                            ></b-form-select2>
                        </b-form-group>
                        <span class="form-text text-muted">Isi sesuai dengan legalitas kepemilikan farm/kandang.</span>
                    </validationProvider>
                </div>
            </div>
            <!--begin::Form Group-->
            <div v-if="kepemilikan != 1">
              <div class="form-group row">
                  <label class="col-xl-3 col-lg-3 col-form-label">Pemilik Farm/Kandang</label>
                  <div class="col-lg-9 col-xl-7">
                      <validationProvider name="Pemilik Farm/Kandang" rules="required|min:5|max:255" v-slot="{ valid, errors }">
                          <b-form-group
                              id="input-group-peternak-22" 
                              label-for="input-peternak-22"
                              :invalid-feedback="errors[0]"
                              class="mb-0"
                              :state="valid">
                              <b-form-input
                              autocomplete="off"
                              id="input-peternak-22"
                              v-model="form.farm.hakmilik"
                              class="form-control form-control-solid"
                              required
                              size="lg"
                              placeholder="Nama Pemilik Farm/Kandang"
                              :state="valid"
                              ></b-form-input>
                          </b-form-group>
                          <span class="form-text text-muted">Isi dengan nama lengkap Perusahaan/Instansi/Perorangan.</span>
                      </validationProvider>
                  </div>
              </div>
            </div>        
            <!--begin::Form Group-->
            <div class="form-group row">
                <label class="col-xl-3 col-lg-3 col-form-label">Data Lokasi</label>
                <div class="col-lg-9 col-xl-7">
                    <div>
                      <div class="checkbox-inline">
                          <label class="checkbox">
                              <input type="checkbox" @change="dialogCustomLok = true" v-model="form.farmlokasi"/>
                              <span></span>
                              {{( form.farmlokasi ? 'Default sama dengan data lokasi Peternak/Poultry Shop' : 'Custom data lokasi' )}}
                          </label>
                      </div>
                      <span class="form-text text-muted">Hilangkan centang untuk custom lokasi farm</span>
                  </div>
                </div>
            </div>    
            <div v-if="showfarmlok">
              <!--begin::Form Group-->
              <div class="form-group row">
                  <label class="col-xl-3 col-lg-3 col-form-label">Provinsi</label>
                  <div class="col-lg-9 col-xl-7">
                      <validationProvider name="Provinsi Farm" rules="required" v-slot="{ valid, errors }">
                          <b-form-group
                              id="input-group-peternak-10" 
                              label-for="input-peternak-10"
                              :invalid-feedback="errors[0]"
                              class="mb-0"
                              :state="valid">
                              <b-form-select2
                              id="input-peternak-10"
                              v-model="form.farm.provinsi"
                              required
                              variant="solid"
                              size="lg"
                              :options="opsProvinsi"
                              placeholder="Silahkan pilih Provinsi Farm"
                              :state="valid"
                              ></b-form-select2>
                          </b-form-group>
                      </validationProvider>
                  </div>
              </div>                        
              <!--begin::Form Group-->
              <div class="form-group row">
                  <label class="col-xl-3 col-lg-3 col-form-label">Kabupaten</label>
                  <div class="col-lg-9 col-xl-7">
                      <validationProvider name="Kabupaten Farm" rules="required" v-slot="{ valid, errors }">
                          <b-form-group
                              id="input-group-peternak-11" 
                              label-for="input-peternak-11"
                              :invalid-feedback="errors[0]"
                              class="mb-0"
                              :state="valid">
                              <b-form-select2
                              id="input-peternak-11"
                              v-model="form.farm.kabupaten"
                              required
                              variant="solid"
                              size="lg"
                              :options="opsKabupatenFarm"
                              placeholder="Silahkan pilih Kabupaten Farm"
                              :state="valid"
                              ></b-form-select2>
                          </b-form-group>
                      </validationProvider>
                  </div>
              </div>          
              <!--begin::Form Group-->
              <div class="form-group row">
                  <label class="col-xl-3 col-lg-3 col-form-label">Kecamatan</label>
                  <div class="col-lg-9 col-xl-7">
                      <validationProvider name="Kecamatan Farm" rules="required" v-slot="{ valid, errors }">
                          <b-form-group
                              id="input-group-peternak-12" 
                              label-for="input-peternak-12"
                              :invalid-feedback="errors[0]"
                              class="mb-0"
                              :state="valid">
                              <b-form-select2
                              id="input-peternak-12"
                              v-model="form.farm.kecamatan"
                              required
                              variant="solid"
                              size="lg"
                              :options="opsKecamatanFarm"
                              placeholder="Silahkan pilih Kecamatan Farm"
                              :state="valid"
                              ></b-form-select2>
                          </b-form-group>
                      </validationProvider>
                  </div>
              </div>
              <!--begin::Form Group-->
              <div class="form-group row">
                  <label class="col-xl-3 col-lg-3 col-form-label">Desa</label>
                  <div class="col-lg-9 col-xl-7">
                      <validationProvider name="Desa Farm" rules="required" v-slot="{ valid, errors }">
                          <b-form-group
                              id="input-group-peternak-13" 
                              label-for="input-peternak-13"
                              :invalid-feedback="errors[0]"
                              class="mb-0"
                              :state="valid">
                              <b-form-select2
                              id="input-peternak-13"
                              v-model="form.farm.desa"
                              required
                              variant="solid"
                              size="lg"
                              :options="opsDesaFarm"
                              placeholder="Silahkan pilih Desa Farm"
                              :state="valid"
                              ></b-form-select2>
                          </b-form-group>
                      </validationProvider>
                  </div>
              </div>
              <!--begin::Form Group-->
              <div class="form-group row">
                  <label class="col-xl-3 col-lg-3 col-form-label">Alamat</label>
                  <div class="col-lg-9 col-xl-7">
                      <validationProvider name="Alamat Farm" rules="required|max:255" v-slot="{ valid, errors }">
                          <b-form-group
                              id="input-group-peternak-14" 
                              label-for="input-peternak-14"
                              :invalid-feedback="errors[0]"
                              class="mb-0"
                              :state="valid">
                              <b-form-input
                              autocomplete="off"
                              id="input-peternak-14"
                              v-model="form.farm.alamat"
                              class="form-control form-control-solid"
                              required
                              size="lg"
                              placeholder="Alamat Farm"
                              :state="valid"
                              ></b-form-input>
                          </b-form-group>
                          <span class="form-text text-muted">Tulis nama jalan, nomor rumah, nomor kompleks, nama gedung, lantai atau nomor unit.</span>
                      </validationProvider>
                  </div>
              </div>
              <!--begin::Form Group-->
              <div class="form-group row">
                  <label class="col-xl-3 col-lg-3 col-form-label">Tag Lokasi</label>
                  <div class="col-lg-9 col-xl-7">
                    <b-form-group
                        id="input-group-peternak-15" 
                        label-for="gmaps"
                        invalid-feedback="Tag lokasi wajib diisi"
                        class="mb-0"
                        :state="cekgmapsfarm">
                        <div style="display: none">
                          <input
                            id="pac-input-farm"
                            ref="alamatfarm"
                            class="controls"
                            type="text"
                            placeholder="Enter a Farm location"
                          />
                        </div>

                        <div class="rounded iframe-content">
                          <div id="gmapsfarm" ref="goomapsfarm"></div>
                        </div>
                    </b-form-group>
                  </div>
              </div>
            </div>
            
            <!--begin::Form Group-->
            <div class="separator separator-dashed my-5 mt-15"></div>
            <!--begin::Form Group-->
            <div class="row">
                <label class="col-xl-3"></label>
                <div class="col-lg-9 col-xl-7">
                    <h5 class="font-weight-bold mb-6">Rincian:</h5>
                </div>
            </div>       
            <!--begin::Form Group-->
            <div class="form-group row">
                <label class="col-xl-3 col-lg-3 col-form-label">Jml Kandang</label>
                <div class="col-lg-9 col-xl-7">
                    <validationProvider name="Jml Kandang" rules="min_value:0|max_value:1000000000" v-slot="{ valid, errors }">
                        <b-form-group
                            id="input-group-peternak-16" 
                            label-for="input-peternak-16"
                            :invalid-feedback="errors[0]"
                            class="mb-0"
                            :state="valid">
                            <vue-number-input 
                              :attrs="{ id: `input-peternak-16`, class: `b-form-spinbutton form-control form-control-solid d-flex align-items-stretch` }"
                              v-model="form.farm.kandang"
                              :min="0"
                              size="large"
                              :max="1000000000"
                              center
                              controls
                            ></vue-number-input>
                        </b-form-group>
                    </validationProvider>
                </div>
            </div> 
            <!--begin::Form Group-->
            <div class="form-group row">
                <label class="col-xl-3 col-lg-3 col-form-label">Jml Flock</label>
                <div class="col-lg-9 col-xl-7">
                    <validationProvider name="Jml Flock" rules="min_value:0|max_value:1000000000" v-slot="{ valid, errors }">
                        <b-form-group
                            id="input-group-peternak-17" 
                            label-for="input-peternak-17"
                            :invalid-feedback="errors[0]"
                            class="mb-0"
                            :state="valid">
                            <vue-number-input 
                              :attrs="{ id: `input-peternak-17`, class: `b-form-spinbutton form-control form-control-solid d-flex align-items-stretch` }"
                              v-model="form.farm.flock"
                              :min="0"
                              size="large"
                              :max="1000000000"
                              center
                              controls
                            ></vue-number-input>
                        </b-form-group>
                    </validationProvider>
                </div>
            </div>
            <!--begin::Form Group-->
            <div class="form-group row">
                <label class="col-xl-3 col-lg-3 col-form-label">Kapasitas Farm</label>
                <div class="col-lg-9 col-xl-7">
                    <validationProvider name="Kapasitas Farm" rules="min_value:0|max_value:1000000000" v-slot="{ valid, errors }">
                        <b-form-group
                            id="input-group-peternak-18" 
                            label-for="input-peternak-18"
                            :invalid-feedback="errors[0]"
                            description="Jumlah kapasitas total pada farm (ekor)"
                            class="mb-0"
                            :state="valid">
                            <vue-number-input 
                              :attrs="{ id: `input-peternak-18`, class: `b-form-spinbutton form-control form-control-solid d-flex align-items-stretch` }"
                              v-model="form.farm.kapasitas"
                              :min="0"
                              size="large"
                              :max="1000000000"
                              center
                              controls
                            ></vue-number-input>
                        </b-form-group>
                    </validationProvider>
                </div>
            </div>
            <!--begin::Form Group-->
            <div class="form-group row">
                <label class="col-xl-3 col-lg-3 col-form-label">Jml SDM</label>
                <div class="col-lg-9 col-xl-7">
                    <validationProvider name="Jml SDM" rules="min_value:0|max_value:1000000000" v-slot="{ valid, errors }">
                        <b-form-group
                            id="input-group-peternak-19" 
                            label-for="input-peternak-19"
                            :invalid-feedback="errors[0]"
                            class="mb-0"
                            :state="valid">
                            <vue-number-input 
                              :attrs="{ id: `input-peternak-19`, class: `b-form-spinbutton form-control form-control-solid d-flex align-items-stretch` }"
                              v-model="form.farm.sdm"
                              :min="0"
                              size="large"
                              :max="1000000000"
                              center
                              controls
                            ></vue-number-input>
                        </b-form-group>
                    </validationProvider>
                </div>
            </div>
        </div>

        <div class="card-footer">
          <div class="row">
            <div class="col-lg-3"></div>
            <div class="col-lg-9">
              <button 
                @click="onSubmit" 
                ref="kt_submit"
                class="btn btn-primary font-weight-bold"
              >
                <i class="la la-save"></i>
                Simpan
              </button>
              &nbsp;
              <button
                @click="onReset"
                class="btn btn-clean font-weight-bold"
              >
                <i class="la la-recycle"></i>
                Reset
              </button>
              &nbsp;
              <button
                type="button"
                @click="closeDialog()"
                class="btn btn-danger font-weight-bold"
              >
                <i class="la la-close"></i>
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    <!--end::Form-->
    </b-form>
    </ValidationObserver>

    <v-dialog
    v-model="dialogCustomLok"
    max-width="300"
    persistent>
      <v-card>
        <v-card-title class="headline">Lokasi Farm</v-card-title>

        <v-card-text>
          Apakah anda yakin akan melanjutkan tindakan
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <b-button
          variant="link-success"
          class="m-3"
          @click="cancelCustomLokasi">
            BATAL
          </b-button>

          <b-button
          variant="link-danger"
          class="m-3"
          @click="customLokasi">
            LANJUTKAN
          </b-button>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>

</template>

<script>
import { mapGetters } from "vuex";
import { Loader } from '@googlemaps/js-api-loader';
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import MasterService from "@/core/services/api/master/master.service";
import WilayahService from "@/core/services/api/master/wilayah.service";
import PeternakService from "@/core/services/api/master/peternak.service";
import ErrorService from "@/core/services/error.service";

export default {
  name: "nasional-distribusi-add-master-peternak",
  props: {
    sdata: {
      required: true
    }
  },
  data() {
    return {
      dialogCustomLok: false,
      showfarmlok: false,
      loading: true,
      form: {
        nama: null,
        nib: null,
        kategori: null,
        usaha: [{
          komoditas: null,
          tipe: null,
          usaha: null
        }],
        pj: null,
        pj_nik: null,
        pj_email: null,
        pj_telp: null,
        provinsi: null,
        kabupaten: null,
        kecamatan: null,
        desa: null,
        alamat: null,
        geotag: null,
        farmlokasi: true,
        farm: {
          nama: null,
          sistem_kandang: null,
          milik: null,
          hakmilik: null,
          lokasi: true,
          provinsi: null,
          kabupaten: null,
          kecamatan: null,
          desa: null,
          alamat: null,
          geotag: null,
          kandang: 0,
          flock: 0,
          kapasitas: 0,
          sdm: 0
        }
      },
      gooMaps: null,
      gooWindow: null,
      gooMarker: null,
      gooMapsFarm: null,
      gooWindowFarm: null,
      gooMarkerFarm: null,
      opsKategoriUsaha: [{ 
        text: 'Silahkan pilih Kategori Usaha', 
        value: null 
      }],
      opsTernakKomoditas: [{
        value: null,
        text: "Silahkan pilih Komoditas"
      }],
      opsTernakTipe: [{
        value: null,
        text: "Silahkan pilih Tipe"
      }],
      opsUsaha: [{
        value: null,
        text: "Silahkan pilih Jenis Usaha"
      }],
      opsSistemKandang: [{
        value: null,
        text: "Silahkan pilih Sistem Kandang"
      }],
      opsKepemilikan: [{
        value: null,
        text: "Silahkan pilih Kepemilikan"
      }],
      opsProvinsi: [{
        value: null,
        text: "Silahkan pilih Provinsi"
      }],
      opsKabupaten: [{
        value: null,
        text: "Silahkan pilih Kabupaten"
      }],
      opsKecamatan: [{
        value: null,
        text: "Silahkan pilih Kecamatan"
      }],
      opsDesa: [{
        value: null,
        text: "Silahkan pilih Desa"
      }],
      opsKabupatenFarm: [{
        value: null,
        text: "Silahkan pilih Kabupaten"
      }],
      opsKecamatanFarm: [{
        value: null,
        text: "Silahkan pilih Kecamatan"
      }],
      opsDesaFarm: [{
        value: null,
        text: "Silahkan pilih Desa"
      }],
      show: true
    }
  },
  computed: {
    ...mapGetters(["currentUser"]),
    identitas() {
      if (!this.form.kategori || this.opsKategoriUsaha.length < 2) return null
      const cekData = this.opsKategoriUsaha.find(o => o.value === this.form.kategori)
      return cekData.identitas
    },
    kepemilikan() {
      if (!this.form.farm.milik) return 1
      const cekData = this.opsKepemilikan.find(o => o.value === this.form.farm.milik)
      return cekData.jns
    },
    cekgmaps() {
      if (this.form.geotag) return true
      return false
    },
    cekgmapsfarm() {
      if (this.form.farm.geotag) return true
      return false
    },
    opsGroupTipe () {
      return this.opsTernakTipe.reduce((acc, obj) => {
        const property = obj['komoditas'];
        acc[property] = acc[property] || [];
        acc[property].push(obj);
        return acc;
      }, {});
    },
    opsGroupUsaha () {
      return this.opsUsaha.reduce((acc, obj) => {
        const property = obj['komoditas'];
        acc[property] = acc[property] || [];
        acc[property].push(obj);
        return acc;
      }, {});
    }
  },
  watch: {
    'form.milik' (val, old) {
      if (val != old) {
        this.form.hakmilik = null
      }
    },
    'form.provinsi' (val, old) {
      if (val != old) {
        this.form.geotag = null
        if (val == null) {
          this.opsKabupaten= [{
            value: null,
            text: "Silahkan pilih Kabupaten"
          }]
          return
        }

        if (this.form.kabupaten && val != this.form.kabupaten.toString().substring(0, 2)) {
          this.form.kabupaten = null
          this.form.kecamatan = null
          this.form.desa = null
        }
        this.getKabupaten({ provinsi: val, aks: 1 })
        // this.centerMaps(val, 'provinsi')
        this.deleteMarker()
      }
    },
    'form.kabupaten' (val, old) {
      if (val != old) {
        this.form.geotag = null
        if (val == null) {
          this.opsKecamatan= [{
            value: null,
            text: "Silahkan pilih Kecamatan"
          }]
          return
        }
        if (this.form.kecamatan && val != this.form.kecamatan.toString().substring(0, 4)) {
          this.form.kecamatan = null
          this.form.desa = null
        }
        this.getKecamatan({ kabupaten: val, aks: 1 })
        this.centerMaps(val, 'kabupaten')
        this.deleteMarker()
      }
    },
    'form.kecamatan' (val, old) {
      if (val != old) {
        this.form.geotag = null
        if (val == null) {
          this.opsDesa= [{
            value: null,
            text: "Silahkan pilih Desa"
          }]  
          return
        }
        if (this.form.desa && val != this.form.desa.toString().substring(0, 6)) {
          this.form.desa = null
        }
        this.getDesa({ kecamatan: val, aks: 1 })
        // this.centerMaps(val, 'kecamatan')
        this.deleteMarker()
      }
    },
    'form.desa' (val, old) {
      if (val != old) {
        this.form.geotag = null
        // this.centerMaps(val, 'desa')
        this.deleteMarker()
      }
    },
    'form.farm.provinsi' (val, old) {
      if (val != old) {
        this.form.farm.geotag = null
        if (val == null) {
          this.opsKabupaten= [{
            value: null,
            text: "Silahkan pilih Kabupaten"
          }]
          return
        }

        if (this.form.farm.kabupaten && val != this.form.farm.kabupaten.toString().substring(0, 2)) {
          this.form.farm.kabupaten = null
          this.form.farm.kecamatan = null
          this.form.farm.desa = null
        }
        this.getKabupaten({ provinsi: val, aks: 1 }, 'farm')
        // this.centerMaps(val, 'provinsi', 'farm)
        this.deleteMarker('farm')
      }
    },
    'form.farm.kabupaten' (val, old) {
      if (val != old) {
        this.form.farm.geotag = null
        if (val == null) {
          this.opsKecamatan= [{
            value: null,
            text: "Silahkan pilih Kecamatan"
          }]
          return
        }
        if (this.form.farm.kecamatan && val != this.form.farm.kecamatan.toString().substring(0, 4)) {
          this.form.farm.kecamatan = null
          this.form.farm.desa = null
        }
        this.getKecamatan({ kabupaten: val, aks: 1 }, 'farm')
        this.centerMaps(val, 'kabupaten', 'farm')
        this.deleteMarker('farm')
      }
    },
    'form.farm.kecamatan' (val, old) {
      if (val != old) {
        this.form.farm.geotag = null
        if (val == null) {
          this.opsDesa= [{
            value: null,
            text: "Silahkan pilih Desa"
          }]  
          return
        }
        if (this.form.farm.desa && val != this.form.farm.desa.toString().substring(0, 6)) {
          this.form.farm.desa = null
        }
        this.getDesa({ kecamatan: val, aks: 1 }, 'farm')
        // this.centerMaps(val, 'kecamatan', 'farm')
        this.deleteMarker('farm')
      }
    },
    'form.farm.desa' (val, old) {
      if (val != old) {
        this.form.farm.geotag = null
        // this.centerMaps(val, 'desa', 'farm')
        this.deleteMarker('farm')
      }
    }
  },
  methods: {
    closeDialog(data = null) {
        let response = {
          status: true
        }

        if (data) {
          response.refresh = true
          response.data = data
        }
        return this.$emit('close-modal', response)
    },
    addKomoditas(){
      this.form.usaha.push({
            komoditas: null,
            tipe: null,
            usaha: null
          })
    },
    deleteKomoditas(index){
      if (this.form.usaha.length > 1) {
        this.form.usaha.splice(index,1);
      }
      else {
        this.form.usaha = [
          {
            komoditas: null,
            tipe: null,
            usaha: null
          }
        ]
      }
    },
    onChangeKomoditas(index) {
      this.form.usaha[index].tipe = null
      this.form.usaha[index].usaha = null
    },
    customLokasi() {
      if (!this.form.farmlokasi) {
        this.loading = false
        this.showfarmlok = true
        this.form.farm.provinsi = this.form.provinsi
        this.form.farm.kabupaten = this.form.kabupaten
        this.form.farm.kecamatan = this.form.kecamatan
        this.form.farm.desa = this.form.desa
        this.form.farm.alamat = this.form.alamat
        this.form.farm.geotag = this.form.geotag

        setTimeout(() => {
          this.initMaps('farm')
        }, 500);
      } else {
        this.loading = true
        this.showfarmlok = false
        this.form.farm.provinsi = null
        this.form.farm.kabupaten = null
        this.form.farm.kecamatan = null
        this.form.farm.desa = null
        this.form.farm.alamat = null
        this.form.farm.geotag = null
        this.gooMapsFarm = null
        this.gooMarkerFarm = null
        this.gooWindowFarm = null
      }

      this.dialogCustomLok = false
    },
    cancelCustomLokasi() {
      this.form.farmlokasi = !this.form.farmlokasi
      this.dialogCustomLok = false
    },
    centerMaps (kode, ting, form = 'peternak') {
      if (!this.loading) return
      let keyMaps = 'gooMaps'
      let keyKabupaten = 'opsKabupaten'
      let keyKecamatan = 'opsKecamatan'
      let keyDesa = 'opsDesa'
      let latlng = {
        lat: -6.200000,
        lng: 106.816666
      }
      let zoom = 11;
      
      if (form == 'farm') {
        keyMaps = 'gooMapsFarm'
        keyKabupaten = 'opsKabupatenFarm'
        keyKecamatan = 'opsKecamatanFarm'
        keyDesa = 'opsDesaFarm'
      }

      if (ting == 'provinsi') {
        const getDT = this.opsProvinsi.find(o => o.value === kode)
        latlng = {
          lat: parseFloat(getDT.lat),
          lng: parseFloat(getDT.lng)
        }
        zoom = 12;
      }
      else if (ting == 'kabupaten') {
        const getDT = this[keyKabupaten].find(o => o.value === kode)
        latlng = {
          lat: parseFloat(getDT.lat),
          lng: parseFloat(getDT.lng)
        }
        zoom = 13;
      }
      else if (ting == 'kecamatan') {
        const getDT = this[keyKecamatan].find(o => o.value === kode)
        latlng = {
          lat: parseFloat(getDT.lat),
          lng: parseFloat(getDT.lng)
        }
        zoom = 13;
      }
      else if (ting == 'desa') {
        const getDT = this[keyDesa].find(o => o.value === kode)
        latlng = {
          lat: parseFloat(getDT.lat),
          lng: parseFloat(getDT.lng)
        }
        zoom = 13;
      }
      else {
        latlng = { lat: -6.200000, lng: 106.816666 }
      }

      this[keyMaps].setZoom(zoom);
      this[keyMaps].setCenter(latlng);
    },
    deleteMarker(form = 'peternak') {
      if (!this.loading) return
      let keyMarker = 'gooMarker'
      if (form == 'farm') keyMarker = 'gooMarkerFarm'
      this[keyMarker].setMap(null);
    },
    getTipeByKomoditas(id) {
      let data = [{ text: 'Silahkan pilih Tipe', value: null }]
      if(!id) return data
      return [...data, ...this.opsGroupTipe[id]]
    },
    getUsahaByKomoditas(id) {
      let data = [{ text: 'Silahkan pilih Tipe', value: null }]
      if(!id || this.opsGroupUsaha.length < 1) return data
      return [...data, ...this.opsGroupUsaha[id]]
    },
    cekKategoriUsaha() {
      if (!this.sdata.tipe_kode || this.opsKategoriUsaha.length < 2) return

      const getKategori = this.opsKategoriUsaha.find(item => item.identitas == this.sdata.tipe_kode.toUpperCase())
      this.form.kategori = (getKategori ? getKategori.value : null)
      if (this.sdata.tipe_kode == 'nib') this.form.nib = this.sdata.kode
      if (this.sdata.tipe_kode == 'nik') this.form.pj_nik = this.sdata.kode

    },
    getTernakKomoditas() {
      MasterService.getTernakKomoditas()
        .then((res) => {
            if (!res.data.status) {
                ErrorService.message(res.data)
                this.opsTernakKomoditas = [{
                  value: null,
                  text: "Silahkan pilih Komoditas"
                }]
                return;
            }

            this.opsTernakKomoditas = res.data.data
            this.opsTernakKomoditas.push({
              value: null,
              text: "Silahkan pilih Komoditas"
            })
        })
        .catch((err) => {
          this.opsTernakKomoditas = [{
            value: null,
            text: "Tidak ada Komoditas"
          }]
          ErrorService.status(err)
        })
    },
    getTernakTipe() {
      MasterService.getTernakTipe({ child: 1 })
        .then((res) => {
            if (!res.data.status) {
                ErrorService.message(res.data)
                this.opsTernakTipe = [{
                  value: null,
                  text: "Silahkan pilih Tipe"
                }]
                return;
            }

            this.opsTernakTipe = res.data.data
            this.opsTernakTipe.push({
              value: null,
              text: "Silahkan pilih Tipe"
            })
        })
        .catch((err) => {
          this.opsTernakTipe = [{
            value: null,
            text: "Tidak ada Tipe"
          }]
          ErrorService.status(err)
        })
    },
    getTipeUsaha() {
      MasterService.getTipeUsaha({ child: 1 })
        .then((res) => {
            if (!res.data.status) {
                ErrorService.message(res.data)
                this.opsUsaha = [{
                  value: null,
                  text: "Silahkan pilih Jenis Usaha"
                }]
                return;
            }

            this.opsUsaha = res.data.data
            this.opsUsaha.push({
              value: null,
              text: "Silahkan pilih Jenis Usaha"
            })
        })
        .catch((err) => {
          this.opsUsaha = [{
            value: null,
            text: "Tidak ada Jenis Usaha"
          }]
          ErrorService.status(err)
        })
    },
    getKategoriUsaha() {
      MasterService.getKategoriUsaha({ komoditas: this.currentUser.akses.komoditas })
        .then((res) => {
            if (!res.data.status) {
                ErrorService.message(res.data)
                this.opsKategoriUsaha = [{
                  value: null,
                  text: "Silahkan pilih Kategori Usaha"
                }]
                return;
            }

            this.opsKategoriUsaha = res.data.data
            this.opsKategoriUsaha.push({
              value: null,
              text: "Silahkan pilih Kategori Usaha"
            })
            this.cekKategoriUsaha()
        })
        .catch((err) => {
          this.opsKategoriUsaha = [{
            value: null,
            text: "Tidak ada Kategori Usaha"
          }]
          ErrorService.status(err)
        })
    },
    getKepemilikan() {
      MasterService.getKepemilikan()
        .then((res) => {
            if (!res.data.status) {
                ErrorService.message(res.data)
                this.opsKepemilikan = [{
                  value: null,
                  text: "Silahkan pilih Kepemilikan"
                }]
                return;
            }

            this.opsKepemilikan = res.data.data
            this.opsKepemilikan.push({
              value: null,
              text: "Silahkan pilih Kepemilikan"
            })
        })
        .catch((err) => {
          this.opsKepemilikan = [{
            value: null,
            text: "Tidak ada Kepemilikan"
          }]
          ErrorService.status(err)
        })
    },
    getSistemKandang() {
      MasterService.getSistemKandang()
        .then((res) => {
            if (!res.data.status) {
                ErrorService.message(res.data)
                this.opsSistemKandang = [{
                  value: null,
                  text: "Silahkan pilih Sistem Kandang"
                }]
                return;
            }

            this.opsSistemKandang = res.data.data
            this.opsSistemKandang.push({
              value: null,
              text: "Silahkan pilih Sistem Kandang"
            })
        })
        .catch((err) => {
          this.opsSistemKandang = [{
            value: null,
            text: "Tidak ada Sistem Kandang"
          }]
          ErrorService.status(err)
        })
    },
    getProvinsi() {
      WilayahService.getProvinsi({ aks: 1 })
        .then((res) => {
            if (!res.data.status) {
                ErrorService.message(res.data)
                this.opsProvinsi = [{
                  value: null,
                  text: "Silahkan pilih Provinsi"
                }]
                return;
            }

            this.opsProvinsi = res.data.data
            this.opsProvinsi.push({
              value: null,
              text: "Silahkan pilih Provinsi"
            })
        })
        .catch((err) => {
          this.opsProvinsi = [{
            value: null,
            text: "Silahkan pilih Provinsi"
          }]
          ErrorService.status(err)
        })
    },
    getKabupaten(param, form = 'peternak') {
      const fault = [{
        value: null,
        text: "Silahkan pilih Kabupaten"
      }]

      if (!param) {
        if(form == 'farm') this.opsKabupatenFarm = fault 
        else this.opsKabupaten = fault 
        return;
      }
      
      WilayahService.getKabupaten(param)
        .then((res) => {
            if (!res.data.status) {
                ErrorService.message(res.data)
                if(form == 'farm') this.opsKabupatenFarm = fault 
                else this.opsKabupaten = fault 
                return;
            }

            if(form == 'farm') this.opsKabupatenFarm = [ ...res.data.data, ...fault ] 
            else this.opsKabupaten = [ ...res.data.data, ...fault ]
        })
        .catch((err) => {
          if(form == 'farm') this.opsKabupatenFarm = fault 
          else this.opsKabupaten = fault 
          ErrorService.status(err)
        })
    },
    getKecamatan(param, form = 'peternak') {
      const fault = [{
        value: null,
        text: "Silahkan pilih Kecamatan"
      }]

      if (!param) {
        if(form == 'farm') this.opsKecamatanFarm = fault 
        else this.opsKecamatan = fault 
        return;
      }

      WilayahService.getKecamatan(param)
        .then((res) => {
            if (!res.data.status) {
                ErrorService.message(res.data)
                if(form == 'farm') this.opsKecamatanFarm = fault 
                else this.opsKecamatan = fault 
                return;
            }

            if(form == 'farm') this.opsKecamatanFarm = [ ...res.data.data, ...fault ] 
            else this.opsKecamatan = [ ...res.data.data, ...fault ]
        })
        .catch((err) => {
          if(form == 'farm') this.opsKecamatanFarm = fault 
          else this.opsKecamatan = fault 
          ErrorService.status(err)
        })
    },
    getDesa(param, form = 'peternak') {
      if (!param) {
        this.opsDesa = [{
          value: null,
          text: "Silahkan pilih Desa"
        }]
        return;
      }
      const fault = [{
        value: null,
        text: "Silahkan pilih Desa"
      }]

      if (!param) {
        if(form == 'farm') this.opsDesaFarm = fault 
        else this.opsDesa = fault 
        return;
      }

      WilayahService.getDesa(param)
        .then((res) => {
            if (!res.data.status) {
                ErrorService.message(res.data)
                if(form == 'farm') this.opsDesaFarm = fault 
                else this.opsDesa = fault 
                return;
            }

            if(form == 'farm') this.opsDesaFarm = [ ...res.data.data, ...fault ] 
            else this.opsDesa = [ ...res.data.data, ...fault ]
        })
        .catch((err) => {
          if(form == 'farm') this.opsDesaFarm = fault 
          else this.opsDesa = fault 
          ErrorService.status(err)
        })
    },
    initMaps(form = 'peternak') {
      let mapView = this.$refs.goomaps
      let input = this.$refs.alamat;
      let markerconfig = {}
      let latlng = {
        lat: -6.200000,
        lng: 106.816666
      }

      let keyMaps = 'gooMaps'
      let keyMarker = 'gooMarker'
      let keyWindow = 'gooWindow'

      if (form == 'farm') {
        keyMaps = 'gooMapsFarm'
        keyMarker = 'gooMarkerFarm'
        keyWindow = 'gooWindowFarm'
        mapView = this.$refs.goomapsfarm
        input = this.$refs.alamatfarm
        if (this.form.geotag) {
          latlng = this.form.geotag
          markerconfig.position = latlng
        }
        else{
          if (this.form.kabupaten) {
            const getDT = this.opsKabupaten.find(o => o.value === this.form.kabupaten)
            latlng = {
              lat: parseFloat(getDT.lat),
              lng: parseFloat(getDT.lng)
            }
          }
        }
      }

      const loader = new Loader({
          apiKey: process.env.VUE_APP_GOOGLE_MAP_API_KEY,
          version: "weekly",
          libraries: ["places"]
      });

      loader.load()
        .then((google) => {
          this[keyMaps] = new google.maps.Map(mapView, {
            center: latlng,
            zoom: 11,
            maId: "f7a6bf3173b56abc",
            controlSize: 25,
            mapTypeId: "roadmap",
            mapTypeControlOptions: {
              mapTypeIds: [],
            },
            streetViewControl: false
          });

          this[keyWindow] = new google.maps.InfoWindow({ content: "Lokasi dipilih" });

          markerconfig.map = this[keyMaps]

          this[keyMarker] = new google.maps.Marker(markerconfig);

          this[keyMaps].controls[google.maps.ControlPosition.TOP_LEFT].push(input);

          const autocomplete = new google.maps.places.Autocomplete(input);
          autocomplete.bindTo("bounds", this[keyMaps]);
          autocomplete.setFields(["place_id", "geometry"]);

          if (form == 'farm') this.loading = true

          this[keyMaps].addListener("click", (mapsMouseEvent) => {
            this[keyWindow].close();
            this[keyMarker].setMap(null);

            this[keyMarker] = new google.maps.Marker({ 
              position: mapsMouseEvent.latLng,
              map: this[keyMaps] 
            });
            
            this[keyMarker].setVisible(true);
            this[keyWindow].open(this[keyMaps], this[keyMarker]);

            if (form == 'farm') this.form.farm.geotag = mapsMouseEvent.latLng.toJSON()
            else this.form.geotag = mapsMouseEvent.latLng.toJSON()
          });

          autocomplete.addListener("place_changed", () => {
            this[keyWindow].close();
            const place = autocomplete.getPlace();

            if (!place.geometry || !place.geometry.location) {
              return;
            }

            if (place.geometry.viewport) {
              this[keyMaps].fitBounds(place.geometry.viewport);
            } else {
              this[keyMaps].setCenter(place.geometry.location);
              this[keyMaps].setZoom(17);
            }
            
            this[keyMarker].setPlace({
              placeId: place.place_id,
              location: place.geometry.location,
            });

            this[keyMarker].setVisible(true);
            this[keyWindow].open(this[keyMaps], this[keyMarker]);
            
            if (form == 'farm') this.form.farm.geotag = place.geometry.location.toJSON()
            else this.form.geotag = place.geometry.location.toJSON()
          });

        })      
        .catch(err => {
            ErrorService.message({
              action: 'Peringatan',
              message: 'Maps gagal dimuat',
            })
          }
        )
    },
    removeSpinner(button) { 
      button.classList.remove(
        "spinner",
        "spinner-light",
        "spinner-right"
      );
    },
    onSubmit(evt) {
      evt.preventDefault()
      this.$refs.formAdd.validate().then(success => {
        if (!success) {
            ErrorService.message({
              action: 'Peringatan',
              message: 'Silahkan lengkapi form data terlebih dahulu',
            })
            return;
        }

        if(!this.identitas) {
            ErrorService.message({
              action: 'Peringatan',
              message: 'Silahkan lengkapi form data terlebih dahulu',
            })
            return
        }

        if(!this.form.farm.milik) {
            this.form.farm.milik = 1
        }

        if (this.identitas == 'NIK') {
          this.form.nama = null
          this.form.nib = null
        }

        // set spinner to submit button
        const submitButton = this.$refs["kt_submit"];
        submitButton.classList.add("spinner", "spinner-light", "spinner-right");

        // dummy delay
        // setTimeout(() => {
            PeternakService.addNasionalPeternak({ ...this.form, identitas: this.identitas })
                .then((res) => {
                    this.removeSpinner(submitButton)
                    ErrorService.message(res.data)
                    
                    if (res.data.status) {
                      this.closeDialog({
                          tipe_kode: 'nip',
                          kode: res.data.data.kode
                        })
                    }
                    return;
                })
                .catch((err) => {
                  this.removeSpinner(submitButton)
                  ErrorService.status(err)
                })

        // }, 1000);
      })
    },
    onReset(evt) {
      evt.preventDefault()
      // Reset our form values
      
      this.form.nama = null
      this.form.kategori = null
      this.form.nib = null
      this.form.usaha = [{
        komoditas: null,
        tipe: null,
        usaha: null
      }]
      this.form.pj = null
      this.form.pj_nik = null
      this.form.pj_email = null
      this.form.pj_telp = null
      this.form.provinsi = null
      this.form.kabupaten = null
      this.form.alamat = null
      this.form.geotag = null
      
      this.farmlokasi = true
      this.form.farm = {
        nama: null,
        sistem_kandang: null,
        milik: null,
        hakmilik: null,
        lokasi: true,
        provinsi: null,
        kabupaten: null,
        kecamatan: null,
        desa: null,
        alamat: null,
        geotag: null,
        kandang: 0,
        flock: 0,
        kapasitas: 0,
        sdm: 0
      }
      
      this.loading = true
      this.showfarmlok = false
      this.gooMapsFarm = null
      this.gooMarkerFarm = null
      this.gooWindowFarm = null

      this.cekKategoriUsaha()
      
      // Trick to reset/clear native browser form validation state
      this.show = false
      this.$nextTick(() => {
        this.show = true
        setTimeout(() => {
          this.initMaps()        
        }, 500);
      })
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Master Data Peternak" }]);
    if (!this.currentUser.menu.peternak) {
      return this.$router.push({ name: 'dashboard'}).catch(()=>{})
    }
    if (!this.sdata) {
      return this.closeDialog()
    }
    this.getKategoriUsaha()
    this.getTernakKomoditas()
    this.getTernakTipe()
    this.getTipeUsaha()
    this.getKepemilikan()
    this.getProvinsi()
    this.getSistemKandang()
    this.initMaps()
  },
};
</script>
<style lang="scss">
  #nasional-distribusi-add-master-peternak {
    .iframe-content {
      height: 400px;
      overflow: auto;
      
      #gmaps, #gmapsfarm {
        height: 100%;
      }
    }

    .controls {
      background-color: #fff;
      border-radius: 2px;
      border: 1px solid transparent;
      box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
      box-sizing: border-box;
      font-family: Roboto;
      font-size: 15px;
      font-weight: 300;
      height: 29px;
      margin-top: 10px;
      outline: none;
      padding: 0 11px 0 13px;
      text-overflow: ellipsis;
    }

    .controls:focus {
      border-color: #4d90fe;
    }
  }
</style>